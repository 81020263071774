<template>
  <div>
    <q-form ref="editForm">
      <c-card class="cardClassDetailForm" title="LBLDETAIL">
        <template slot="card-button">
          <q-btn-group outline >
              <c-btn v-if="isOld" label="출력" icon="print" @btnClicked="printStatus" />
              <c-btn v-if="editable&&!disabled&&isOld" label="LBLREMOVE" icon="delete" @btnClicked="deleteStatus" />
              <c-btn 
                v-if="editable && !disabled" 
                :isSubmit="isSave"
                :url="saveUrl"
                :param="facilityStatusTable"
                :mappingType="mappingType"
                label="LBLSAVE" 
                icon="save"
                @beforeAction="saveStatus"
                @btnCallback="saveStatusCallback" />
          </q-btn-group>
        </template>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <c-text
              :required="true"
              :editable="editable"
              :disabled="disabled"
              label="현황표명"
              name="facilityStatusTableName"
              v-model="facilityStatusTable.facilityStatusTableName">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <c-plant
              :required="true"
              :disabled="disabled||isOld"
              :editable="editable"
              name="plantCd"
              v-model="facilityStatusTable.plantCd"
              @datachange="datachange">
            </c-plant>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <c-datepicker
              :disabled="disabled"
              :editable="editable"
              label="허가일"
              name="permitDate"
              v-model="facilityStatusTable.permitDate"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <c-datepicker
              :disabled="disabled"
              :editable="editable"
              default="today"
              type="year"
              label="LBLYEAR"
              name="year"
              v-model="facilityStatusTable.year"
            />
          </div>
          <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <c-text
              :editable="editable"
              :readonly="true"
              label="작성정보"
              name="reg"
              v-model="reg">
            </c-text>
          </div> -->
        </template>
      </c-card>
      <c-card class="cardClassDetailForm" noHeader>
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <c-text
              :editable="editable"
              :readonly="true"
              label="회사명"
              name="companyName"
              v-model="facilityStatusTable.companyName">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <c-text
              :editable="editable"
              :readonly="true"
              label="대표자"
              name="managerName"
              v-model="facilityStatusTable.managerName">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl-4">
            <c-text
              :editable="editable"
              :disabled="disabled"
              :readonly="true"
              label="소재지"
              name="attrVal2"
              v-model="facilityStatusTable.attrVal2">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <c-text
              :editable="editable"
              :disabled="disabled"
              :readonly="true"
              label="전화번호"
              name="phoneNo"
              v-model="facilityStatusTable.phoneNo">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <c-text
              :editable="editable"
              :disabled="disabled"
              :readonly="true"
              label="시설구분"
              name="attrVal3"
              v-model="facilityStatusTable.attrVal3">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <c-text
              :editable="editable"
              :readonly="true"
              label="사업자번호"
              name="bizNo"
              v-model="facilityStatusTable.bizNo">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6 col-lg-2 col-xl-2">
            <c-text
              :editable="editable"
              :disabled="disabled"
              :readonly="true"
              label="대표자 email"
              name="managerEmail"
              v-model="facilityStatusTable.managerEmail">
            </c-text>
          </div>
        </template>
      </c-card>
    </q-form>
    <div v-if="isOld" class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
        <c-table
          ref="table"
          title="유해화학물질관리자 목록"
          :columns="grid.columns"
          :data="facilityStatusTable.managers"
          :gridHeight="gridHeight"
          :editable="editable&&!disabled"
          :hideBottom="true"
          :isExcelDown="false"
          :filtering="false"
          :isFullScreen="false"
          :columnSetting="false"
        >
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
        <c-table
          ref="table"
          title="검사대상 유해화학물질 취급시설 개수"
          :columns="gridFacility.columns"
          :data="gridFacilityData"
          :gridHeight="gridHeight"
          :hideBottom="true"
          :isExcelDown="false"
          :filtering="false"
          :isFullScreen="false"
          :columnSetting="false"
        >
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'facility-status-table-base-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        chmFacilityStatusTableId: '',
      }),
    },
    facilityStatusTable: {
      type: Object,
      default: () => ({
        chmFacilityStatusTableId: '',  // 취급시설 현황표 일련번호
        facilityStatusTableName: '',  // 현황표명
        plantCd: '',  // 사업장코드
        year: '',
        permitDate: '',  // 허가일
        companyName: '',  // 회사명
        managerName: '',  // 대표자
        managerEmail: '',  // 대표자 E-MAIL
        phoneNo: '',  // 전화번호
        bizNo: '',  // 사업자번호
        remarks: '',  // 비고
        attrVal1: '',  // 회사id
        attrVal2: '',  // 소재지
        attrVal3: '',  // 시설구분
        regDtStr: '',
        regUserName: '',
        managers: [], // 유해화학물질관리자
        facilities: [], // 시설
        hazardChems: [], // 유해화학물질 취급현황
        equipDevices: [], // 설비 장치
        equipPowers: [], // 설비 동력기계
      }),
    },
    hcfFacilityTypeItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'seniorUserName',
            field: 'seniorUserName',
            label: 'LBLNAME',
            align: 'left',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'relatedCertificate',
            field: 'relatedCertificate',
            label: '관련자격증',
            align: 'center',
            style: 'width:130px',
            sortable: true,
          },
          {
            name: 'dismissalFlagName',
            field: 'dismissalFlagName',
            label: '선임구분',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'seniorDate',
            field: 'seniorDate',
            label: '선임일',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ],
        data: [],
      },
      gridFacility: {
        columns: [
          {
            name: 'gubun',
            field: 'gubun',
            label: '구분',
            align: 'center',
            style: 'width:60%',
            sortable: true,
          },
          {
            name: 'count',
            field: 'count',
            label: '개수',
            align: 'right',
            style: 'width:40%',
            sortable: true,
            type: 'cost',
          },
        ],
      },
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      editable: true,
      isSave: false,
      printUrl: '', 
      saveUrl: '', 
      deleteUrl: '', 
      mappingType: 'PUT',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    // reg() {
    //   return this.facilityStatusTable.regUserName + ' / ' + this.facilityStatusTable.regDtStr
    // },
    isOld() {
      return Boolean(this.popupParam.chmFacilityStatusTableId)
    },
    gridFacilityData() {
      let _data = [];
      if (this.facilityStatusTable.facilities && this.facilityStatusTable.facilities.length > 0 
        && this.hcfFacilityTypeItems && this.hcfFacilityTypeItems.length > 0) {
          this.$_.forEach(this.hcfFacilityTypeItems, item => {
            let typeData = this.$_.filter(this.facilityStatusTable.facilities, { hcfFacilityTypeCd: item.code });
            if (item.code !== 'HFT0000040') {
              _data.push({
                gubun: item.codeName,
                count: typeData ? typeData.length : 0,
              })
            } else {
              _data.push({
                gubun: `${item.codeName} (km)`,
                count: typeData ? this.$_.sum(this.$_.map(typeData, 'distance')) : 0,
              })
            }
          })
      }
      return _data;
    },
    gridHeight() {
      let _height = this.contentHeight ? this.contentHeight - 400 : 500
      return _height + 'px';
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.printUrl = selectConfig.chm.facilityStatusTable.print.url;
      this.saveUrl = transactionConfig.chm.facilityStatusTable.insert.url;
      this.deleteUrl = transactionConfig.chm.facilityStatusTable.delete.url;
    },
    datachange() {
      this.$emit('plantChange')
    },
    saveStatus() {
      if (this.popupParam.chmFacilityStatusTableId) {
        this.mappingType = 'PUT'
        this.saveUrl = transactionConfig.chm.facilityStatusTable.update.url;
      } else {
        this.mappingType = 'POST'
        this.saveUrl = transactionConfig.chm.facilityStatusTable.insert.url;
      }
      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.facilityStatusTable.regUserId = this.$store.getters.user.userId
              this.facilityStatusTable.chgUserId = this.$store.getters.user.userId

              this.isSave = !this.isSave
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveStatusCallback(_result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'chmFacilityStatusTableId', _result.data)
      this.$emit('getDetail');
    },
    deleteStatus() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.chmFacilityStatusTableId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    printStatus() {
      let thisVue = this;
      this.$http.url = this.$format(this.printUrl, this.popupParam.chmFacilityStatusTableId);
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.facilityStatusTable.plantCd,
        printUserName: this.$store.getters.user.userName
      };
      this.$http.request((_result) => {
        let fileOrgNm = this.facilityStatusTable.facilityStatusTableName + '.xlsx';
        let blob = thisVue.$comm.base64ToBlob(_result.data);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
        } else {
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileOrgNm;
          link.click();
        }
      },);

    },
  }
};
</script>